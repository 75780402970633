import moment from 'moment'
import apiJwtConfig from './jwtConfig'

function convertDateToUTC(dateStr) {
  // convert date to UTC
  const localDate = moment(dateStr).local()
  const convertedDate = moment.utc(localDate).format('YYYY-MM-DD')
  return convertedDate
}

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...apiJwtConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns) {
    this.axiosIns = axiosIns

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        /* if (process.env.NODE_ENV === 'development') {
        } */

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        // set base url
        config.baseURL = this.jwtConfig.baseUrl // eslint-disable-line no-param-reassign

        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        /* const { config, response: { status } } = error */
        const { response } = error
        /* const originalRequest = config */

        // if (status === 401) {
        if (response && response.status === 401) {
          console.log('useJwt 401 Unauthorized', response)

          // Clear localstorage
          localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('packagedUserData')
          localStorage.removeItem('companyData')
          localStorage.removeItem('userData')

          // Redirect to login page
          window.location.href = '/' // '/login'

          /* if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest */
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  /* AUTHENTICATION */

  login(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.login}`, ...args)
  }

  checkVerification(vCode, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.users}/email/verification/${vCode}`, ...args)
  }

  register(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.register}`, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.refresh}`, {
      refreshToken: this.getRefreshToken(),
    })
  }

  /* MANAGERS QUESTIONS */

  getQuestionsDigest(...args) {
    const questionArgs = { page: 1, size: 10, ...args[0] }
    return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/questions/digest?page=${questionArgs.page}&size=${questionArgs.size}`)
  }

  addQuestion(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.managers}/questions`, ...args)
  }

  setQuestionTrue(questionId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.managers}/questions/${questionId}/true`, ...args)
  }

  setQuestionFalse(questionId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.managers}/questions/${questionId}/false`, ...args)
  }

  updateQuestion(questionId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.managers}/questions/${questionId}`, ...args)
  }

  /* MANAGERS PROMOTIONS */

  getPromoCopy() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.promotionalCopy}`)
  }

  createPromoCopy(...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.managers}/promotionalcopy`, ...args)
  }

  /* MANAGERS COHORTS */

  getCohortsDigest(...args) {
    const todaysDate = new Date()
    const cohortArgs = {
      page: 1,
      size: 10,
      start: convertDateToUTC(moment(todaysDate).subtract(1, 'year')),
      end: convertDateToUTC(todaysDate),
      ...args[0],
    }
    // console.log(cohortArgs)
    // the API will default to T - 2 weeks for a start date if nothing is provided
    return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/reports/cohorts?start=${cohortArgs.start}&end=${cohortArgs.end}`)
    // return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/reports/cohorts`)
  }

  getCohortTimeline(cohortId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/reports/cohorts/${cohortId}`)
  }

  getCohortQuestionUserHistory(cohortId, questionId) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/reports/cohorts/${cohortId}/question/${questionId}`)
  }

  /* MANAGERS WINNERS */

  getWinnersDigest(...args) {
    const winnerArgs = { page: 1, size: 10, ...args[0] }
    return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/winners?page=${winnerArgs.page}&size=${winnerArgs.size}`)
  }

  setWinnerAwarded(winnerId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.managers}/winners/${winnerId}/awarded`, ...args)
  }

  /* USERS */

  getCurrentUser() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me`)
  }

  updateUser(...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.users}/me`, ...args)
  }

  updatePassword(...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.users}/me/password`, ...args)
  }

  getRegistrationReport(...args) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.registrationReport}`, ...args)
  }
}
