import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    winners: null, // list of winners
  },
  getters: {
    getAllWinners(state) {
      return state.winners
    },
  },
  mutations: {
    SET_WINNERS(state, winners) {
      state.winners = winners
    },
  },
  actions: {
    fetchAllWinners({ commit }, args) {
      useJwt.getWinnersDigest(args)
        .then(result => commit('SET_WINNERS', result.data))
    },
  },
}
