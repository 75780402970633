export default [

  /* COHORT ROUTES */

  { // browse
    path: '/cohorts',
    name: 'browse-cohorts',
    component: () => import('@/views/cohorts/BrowseCohorts.vue'),
    meta: {
      resource: 'Cohort',
      action: 'browse',
    },
  },
  /* { // read
    path: '/cohorts/view/:id',
    name: 'view-cohort',
    component: () => import('@/views/cohorts/ViewCohort.vue'),
    meta: {
      resource: 'Cohort',
      action: 'read',
    },
  }, */
  /* { // update
    path: '/cohorts/edit/:id',
    name: 'edit-cohort',
    component: () => import('@/views/cohorts/EditCohort.vue'),
    meta: {
      resource: 'Cohort',
      action: 'update',
    },
  },
  { // create
    path: '/cohorts/create',
    name: 'create-cohort',
    component: () => import('@/views/cohorts/AddCohort.vue'),
    meta: {
      resource: 'Cohort',
      action: 'create',
    },
  }, */
]
