import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    cohorts: null, // list of cohorts
  },
  getters: {
    getAllCohorts(state) {
      return state.cohorts
    },
  },
  mutations: {
    SET_COHORTS(state, cohorts) {
      state.cohorts = cohorts
    },
  },
  actions: {
    fetchAllCohorts({ commit }, args) {
      useJwt.getCohortsDigest(args)
        .then(result => commit('SET_COHORTS', result.data))
    },
  },
}
