export default [

  /* PROMOTION ROUTES */

  /* { // browse
    path: '/promotions',
    name: 'browse-promotions',
    component: () => import('@/views/promotions/BrowsePromotion.vue'),
    meta: {
      resource: 'Promotion',
      action: 'browse',
    },
  }, */
  /* { // read
    path: '/promotions/view/:id',
    name: 'view-promotion',
    component: () => import('@/views/promotions/ViewPromotion.vue'),
    meta: {
      resource: 'Promotion',
      action: 'read',
    },
  },
  { // update
    path: '/promotions/edit/:id',
    name: 'edit-promotion',
    component: () => import('@/views/promotions/EditPromotion.vue'),
    meta: {
      resource: 'Promotion',
      action: 'update',
    },
  }, */
  { // create
    path: '/promotions/create',
    name: 'create-promotion',
    component: () => import('@/views/promotions/AddPromotion.vue'),
    meta: {
      resource: 'Promotion',
      action: 'create',
    },
  },
]
