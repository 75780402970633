import { $themeConfig } from '@themeConfig'

export default {

  // api base url
  baseUrl: $themeConfig.app.apiBaseUrl,

  // api endpoints
  endpoints: {
    googleAuth: '/api/google/auth',

    // Survivor Pool
    cohorts: '/api/cohorts',
    managers: '/api/managers',
    promotionalCopy: '/api/promotionalcopy',
    questions: '/api/questions',
    users: '/api/users',

    forms: '/api/forms', // TO DELETE
    googleads: '/api/ggads', // TO DELETE

    login: '/api/managers/users/login',
    register: '/api/users/register',
    refresh: '/api/refresh-token', // TODO
    logout: '/api/logout', // TO DELETE?
    registrationReport: '/api/managers/reports/registrations',
  },

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
