import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    registrationReport: null, // list of registrations
  },
  getters: {
    getRegistrationReport(state) {
      return state.registrationReport
    },
  },
  mutations: {
    SET_REGISTRATION_REPORT(state, registrationReport) {
      state.registrationReport = registrationReport
    },
  },
  actions: {
    fetchRegistrationReport({ commit }, args) {
      useJwt.getRegistrationReport(args)
        .then(result => commit('SET_REGISTRATION_REPORT', result.data))
    },
  },
}
