export default [

  /* QUESTION ROUTES */

  { // browse
    path: '/questions',
    name: 'browse-questions',
    component: () => import('@/views/questions/BrowseQuestions.vue'),
    meta: {
      resource: 'Question',
      action: 'browse',
    },
  },
  /* { // read
    path: '/questions/view/:id',
    name: 'view-question',
    component: () => import('@/views/questions/ViewQuestion.vue'),
    meta: {
      resource: 'Question',
      action: 'read',
    },
  },
  { // update
    path: '/questions/edit/:id',
    name: 'edit-question',
    component: () => import('@/views/questions/EditQuestion.vue'),
    meta: {
      resource: 'Question',
      action: 'update',
    },
  }, */
  { // create
    path: '/questions/create',
    name: 'create-question',
    component: () => import('@/views/questions/AddQuestion.vue'),
    meta: {
      resource: 'Question',
      action: 'create',
    },
  },
]
