export default [

  /* REGISTRATION REPORT ROUTES */

  { // browse
    path: '/registration-report',
    name: 'registration-report',
    component: () => import('@/views/registration/RegistrationReport.vue'),
    meta: {
      resource: 'Registration',
      action: 'browse',
    },
  },
]
