import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    questions: null, // list of questions
  },
  getters: {
    getAllQuestions(state) {
      return state.questions
    },
  },
  mutations: {
    SET_QUESTIONS(state, questions) {
      state.questions = questions
    },
  },
  actions: {
    fetchAllQuestions({ commit }, args) {
      useJwt.getQuestionsDigest(args)
        .then(result => commit('SET_QUESTIONS', result.data))
    },
  },
}
