import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import questions from './questions'
import cohorts from './cohorts'
import winners from './winners'
import registration from './registration'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,

    questions,
    cohorts,
    winners,
    registration,
  },
  strict: process.env.DEV,
})
