export default [

  /* WINNER ROUTES */

  { // browse
    path: '/winners',
    name: 'browse-winners',
    component: () => import('@/views/winners/BrowseWinners.vue'),
    meta: {
      resource: 'Winner',
      action: 'browse',
    },
  },
]
